.cell-center {
  text-align: center;
}

.cell-center>* {
  margin: 0 auto;
}

.cell-right {
  text-align: right;
}

.cell-right>* {
  margin: 0 0 0 auto;
}

.cell-sticky-right {
  position: sticky;
  right: 0;

}